import {dynamicSort} from "../../Utilities/Sort";
import {createDescriptionObjectForList, createListFromObject} from "../../Utilities/Types/listUtilities";
import {getRoleId} from "../../Utilities/LocalStorage/storageUtilities";
import {ROLES} from "../../Components/Authorize/roleConstants";

export const courseTypes = {
    RegularCourse: createDescriptionObjectForList(1, "Course"),
    EventCourse: createDescriptionObjectForList(2, "Event"),
    ProfessionalCommunityCourse: createDescriptionObjectForList(3, "Professional Community"),
    Training: createDescriptionObjectForList(4, "Training")
};

export const courseFileUploadTypes = {
    courseBgImageFile: "courseBgImageFile",
    followUpFile: "followUpFile",
    moduleBgImageFile: "moduleBgImageFile"
}

export const moduleNotSelected = "moduleNotSelected";

export const moduleType = {
    courseContent: "Course Content",
    outline: "Outline",
    resources: "Resources",
    general: "General",
    discussionBoard: "Discussion Board"
};

export function isModuleContentGenerated(value) {
    return value === moduleType.outline || value === moduleType.resources || value === moduleType.discussionBoard;
}

export function getModuleTypeList(includeDiscussionBoard){
    const moduleTypes = createListFromObject(moduleType, true)
        .sort(dynamicSort("text"));
    return includeDiscussionBoard
        ? moduleTypes
        : moduleTypes.filter(f => f.text !== moduleType.discussionBoard);
}


export const pageType = {
    courseContent: "Course Content",
    cyk: "Cyk"
};

export const DEFAULT_RETURN_MODULES_LABEL = "Return to Modules";
export const DEFAULT_RETURN_DASHBOARD_LABEL = "Return to My Dashboard";
export const DEFAULT_CYK_LABEL = "Check Your Knowledge";
export const DEFAULT_NEXT_PAGE_LABEL = "Next Page";
export const DEFAULT_PREVIOUS_PAGE_LABEL = "Previous Page";

export function contentRequiresSurvey(value) {
    return value === pageType.cyk;
}

export const pageTypeList = createListFromObject(pageType, true);


export const contentType = {
    html: "Html",
    vimeo: "Vimeo",
    youTube: "YouTube",
    outline: "Outline",
    resources: "Resources",
    objectives: "Objectives"
};

export function contentRequiresRichTextEditor(value) {
    return value === contentType.html
        || value === contentType.outline
        || value === contentType.resources
        || value === contentType.objectives;
}

export function contentRequiresTextField(value) {
    return value === contentType.vimeo || value === contentType.youTube;
}

export function isGeneratedContent(value) {
    return value === pageType.cyk || value === contentType.outline;
}

export const contentTypeList = createListFromObject(contentType, true);

export const professionalCommunitiesContentType = {
    blogs: "Blogs & Websites",
    otherLmsContent: "Courses",
    discussions: "Discussion Boards",
    documents: "Documents",
    products: "Products",
    publications: "Publications",
    trainingVideos: "Training Videos",
    webinars: "Webinars",
};
export const professionalCommunitiesContentTypeClass = {
    "Webinars": "content__webinars",
    "Blogs & Websites": "content__blogs",
    "Training Videos": "content__videos",
    "Documents": "content__documents",
    "Discussion Boards": "content__discussions",
    "Courses": "content__lms",
    "Publications": "content__handouts",
    "Products": "content__products",
}

export const professionalCommunitiesContentTypeList = createListFromObject(professionalCommunitiesContentType, true);

export const trainingType = {
    Day1FloridaModelTraining: createDescriptionObjectForList(1, "Day 1 Florida Model Training"),
    ChairViceChairPrincipalFloridaModelTraining: createDescriptionObjectForList(2, "Chair / Vice Chair / Principal Florida Model Training"),
    DTMCFloridaModelTraining: createDescriptionObjectForList(3, "DTMC Florida Model Training"),
    TrainerFloridaModelTraining: createDescriptionObjectForList(4, "Trainer Florida Model Training"),
    SesirRefresher: createDescriptionObjectForList(5, "SESIR Refresher")
};

export const trainingTypeList = createListFromObject(trainingType, true);

export function getTrainingTypeListByRole(userRoleId = getRoleId()) {
    if(userRoleId === ROLES.Trainer)
        return trainingTypeList.filter(f => f.id < 3);
    return trainingTypeList;
}

export const trainingStatus = {
    AcceptingCompletedParticipantsTrainingOffered: createDescriptionObjectForList(1, "Accepting Completed Participants/Training Offered"),
    VerificationCompleteTrainingClosed: createDescriptionObjectForList(2, "Verification Complete/Training Closed"),
    TrainingRemovedNotOffered: createDescriptionObjectForList(3, "Training Removed/Not Offered"),
};

export const trainingStatusList = createListFromObject(trainingStatus, true);


export const associatedTrainings = {
    MyTrainings: createDescriptionObjectForList(1, "MyTrainings/Co-Trainings"),
};

export const associatedTrainingsList = createListFromObject(associatedTrainings, true);
