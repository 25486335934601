import {ROLES} from "../../Components/Authorize/roleConstants";
import {isNullOrUndefined} from "../../Utilities/commonUtilities";
import {getTodayDateAsString} from "../../Utilities/Types/dateUtilities";
import {courseTypes} from "../Course/courseConstants";
import {STATUS} from "./userConstants";

export const signInModel = ({
                                email,
                                password,
                            } = {}) => ({
    email,
    password,
});

export const userModel = ({
                              accessToken,
                              cellPhone,
                              createDate = null,
                              email,
                              enrollments,
                              firstName = "",
                              isActive = true,
                              lastName = "",
                              password,
                              phone,
                              refreshToken,
                              regionId,
                              roleId = ROLES.Participant,
                              siteAbbreviation,
                              siteGroupId,
                              status = STATUS.Active,
                              userId = 0
                          } = {}) => ({
    accessToken,
    cellPhone,
    createDate,
    email,
    enrollments: enrollments ? enrollments.map((e) => enrollmentModel({...e})) : [],
    firstName,
    isActive,
    lastName,
    password,
    phone,
    refreshToken,
    regionId,
    roleId,
    siteAbbreviation,
    siteGroupId,
    status,
    userId
});

export const enrollmentModel = ({
                                    enrollmentId,
                                    completionWarningMessage = "",
                                    courseId,
                                    userId,
                                    dateCreated,
                                    dateCompleted,
                                    progress,
                                    followUpActivityFileCreationDate = "",
                                    followUpActivityFileId,
                                    followUpStatus,
                                    followUpStatusIsApproved,
                                    followUpStatusIsRejected,
                                    followUpStatusIsSubmitted,
                                    followUpRejectedReason,
                                    isArchived = false,
                                    taskStatuses,
                                    moduleLastAccessed,
                                    allModulesComplete,
                                    courseTitle = "",
                                    trainingTitle = "",
                                    courseDescription,
                                    subjectTags,
                                    course,
                                    assessmentDate = "",
                                    assessmentScore = "",
                                    preAssessmentDate = "",
                                    preAssessmentScore = "",
                                    surveyDate,
                                    bookMark,
                                    details,
                                    creditEarned,
                                    creditOptionSelected,
                                    lastAccessed = getTodayDateAsString()
                                } = {}) => ({
    enrollmentId,
    completionWarningMessage: completionWarningMessage || "",
    courseId,
    userId,
    dateStarted: dateCreated ? dateCreated : getTodayDateAsString(),
    dateFinished: dateCompleted,
    progress: progress ? progress.map((p) => progressModel({...p})) : [],
    followUpActivityFileCreationDate,
    followUpActivityFileId: followUpActivityFileId ? followUpActivityFileId : 0,
    followUpStatus: followUpStatus || "",
    followUpStatusIsApproved,
    followUpStatusIsRejected,
    followUpStatusIsSubmitted,
    followUpRejectedReason: followUpRejectedReason || "",
    isArchived,
    taskStatuses: taskStatuses ? taskStatuses : [],
    moduleLastAccessed: moduleLastAccessed ? moduleLastAccessedModel(moduleLastAccessed) : {},
    allModulesComplete,
    courseTitle: course?.trainingTitleComplete ?? courseTitle,
    trainingTitle: course?.trainingTitle ?? trainingTitle,
    courseDescription,
    subjectTags,
    courseType: !course ? courseTypes.RegularCourse.id : course.courseType,
    assessmentDate,
    assessmentScore,
    preAssessmentDate,
    preAssessmentScore,
    surveyDate,
    bookMark: bookMark || "",
    details: details || "",
    creditEarned: creditEarned || "",
    creditOptionSelected: isNullOrUndefined(creditOptionSelected) ? "" : creditOptionSelected.toString(),
    lastAccessed: lastAccessed
});

export const progressModel = ({
                                  contentProgress,
                                  dateCreated,
                                  dateCompleted,
                                  dateTimeCompletedForAdminEnrollmentDetails,
                                  isAvailable,
                                  moduleId
                              } = {}) => ({
    contentProgress: contentProgress ? contentProgress.map((progress) => contentProgressModel(progress)) : [],
    dateStarted: dateCreated ? dateCreated : "",
    dateFinished: dateCompleted ? dateCompleted : "",
    dateFinishedWithTimeForAdmin: dateTimeCompletedForAdminEnrollmentDetails ? dateTimeCompletedForAdminEnrollmentDetails : "",
    isAvailable: !!isAvailable,
    moduleId,
});

export const contentProgressModel = ({
                                         dateCreated,
                                         dateCompleted,
                                         isAvailable,
                                         moduleContentId
                                     } = {}) => ({
    dateStarted: dateCreated ? dateCreated : "",
    dateFinished: dateCompleted ? dateCompleted : "",
    isAvailable: !!isAvailable,
    moduleContentId
})

export const moduleLastAccessedModel = ({
                                            moduleId,
                                            moduleNumber,
                                            title,
                                            isRequired
                                        } = {}) => ({
    moduleId,
    moduleNumber,
    title,
    isRequired
});